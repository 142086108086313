import { Member, MemberStatus, MemberType } from '../MemberTypes';
import { Nullable } from '../UtilTypes';
import { Position } from './partner.type';
import { ISummoner, Region } from './summoner.type';

export interface ClashTeam {
  id: string;
  name: string;
  externalUrl?: string;
  content: string;
  region: string;
  insertedAt: string;
  userId: string;
  summonerName: Nullable<string>;
  gameName?: string;
  tagLine?: string;
  tier: ClashTier;
  members: ClashTeamMember[];
}

export interface ClashTeamMember {
  id: number;
  memberType: MemberType;
  status?: MemberStatus;
  position: Position;
  introduce: string;
  ipAddress?: string;
  region: Region;
  clanId: number;
  userId: string;
  insertedAt: Date;
  summoner: ISummoner;
  tier: ClashTier;
  isSubscription: boolean;
}

export interface ClashTeamFormState {
  id: Nullable<string>;
  name: Nullable<string>;
  discordUrl: Nullable<string>;
  content: Nullable<string>;
  region: Nullable<string>;
  position: Nullable<Position>;
  summonerName: Nullable<string>;
  tier: Nullable<number>;
  teamMembers: Nullable<{
    [key: string]: {
      name: string;
      gameName?: string;
      tagLine?: string;
    };
  }>;
}

export enum ClashTier {
  All = 0,
  IV = 4,
  III = 3,
  II = 2,
  I = 1,
}

export function isClashTeamMember(
  member: Member | ClashTeamMember
): member is ClashTeamMember {
  return 'tier' in member;
}
