import { useEffect, useState } from 'react';
import { Nullable } from '../types/UtilTypes';
import { Region } from '../types/lol/summoner.type';
import axios, { AxiosError } from 'axios';
import { isProduction } from '../utils/misc';

interface SearchSummonerProps {
  region: Region;
}

interface SoloTierInfo {
  tier: string;
  division: number;
  lp: number;
}

export interface SearchSummoner {
  id: number;
  game_name: string;
  tagline: string;
  profile_image_url: string;
  level: number;
  solo_tier_info: Nullable<SoloTierInfo>;
}

const summonerApiUrl = isProduction()
  ? 'https://lol-api-summoner.op.gg'
  : 'https://lol-api-summoner-stage-pe3ibruo0rcfagcp.op.gg';

export const useSearchSummoner = ({ region }: SearchSummonerProps) => {
  const [summoners, setSummoners] = useState<SearchSummoner[]>([]);
  const [error, setError] = useState<AxiosError>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = async (gameName: string, tagLine?: string) => {
    setSummoners([]);
    setError(undefined);
    setLoading(true);
    try {
      const url = `${summonerApiUrl}/api/v2/${region.toUpperCase()}/complete/summoners?game_name=${gameName}&page=1`;
      const res = await axios.get(tagLine ? `${url}&tagline=${tagLine}` : url);
      setSummoners(res.data?.data);
    } catch (error) {
      setError(error as AxiosError);
    } finally {
      setLoading(false);
    }
  };

  return { summoners, error, loading, fetchData };
};
