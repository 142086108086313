import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  className?: string;
  id: string;
};

const TableHeaderCell: React.FC<Props> = ({ className, id }) => {
  return (
    <div
      className={classNames(
        'text-3xs font-normal text-gray-400 bg-gray-850 py-2 px-2.5 whitespace-nowrap',
        className,
        {
          'text-center first:text-left':
            !className || !/text-(left|center|right)/.test(className),
        }
      )}
    >
      <FormattedMessage id={id} />
    </div>
  );
};

export default TableHeaderCell;
