import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { LOL_REGION_TAG_NAME } from '../utils/constants';
import { getRegion } from '../utils/application';
import { useIntl } from 'react-intl';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { splitSummonerName } from '../utils/tag';
import { SearchSummoner, useSearchSummoner } from '../hooks/summoners';

interface Props {
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
}

const SummonerNameInput = forwardRef(
  (
    {
      register,
      watch,
      setValue,
      disabled = false,
      placeholder,
      className,
      ...rest
    }: Props,
    ref: any
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const listRef = useRef<HTMLUListElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const intl = useIntl();
    const { summoners, fetchData } = useSearchSummoner({
      region: getRegion(),
    });
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    const [selectedSummonerIndex, setSelectedSummonerIndex] =
      useState<number>(0);
    const searchedVisible = useMemo(
      () => inputFocused && summoners.length !== 0,
      [inputFocused, summoners]
    );

    const changeFocusedInput = (focused: boolean) => {
      if (!inputRef?.current) return;
      focused ? inputRef.current?.focus() : inputRef.current?.blur();
    };

    const addTagLineIfNotExists = () => {
      const summonerName = watch('summonerName');
      if (!summonerName) return;

      const { name, tagLine } = splitSummonerName(summonerName);

      if (!tagLine && name.trim() !== '') {
        setValue('summonerName', `${name}${LOL_REGION_TAG_NAME[getRegion()]}`);
      }
    };

    const selectSummoner = (summoner: SearchSummoner) => () => {
      setValue('summonerName', `${summoner.game_name}#${summoner.tagline}`);
      setInputFocused(false);
      changeFocusedInput(false);
    };

    const handleInputKeyDown = (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (!searchedVisible) return;

      const { key } = event;

      if (key === 'ArrowUp') {
        event.preventDefault();
        if (selectedSummonerIndex === 0) return;
        setSelectedSummonerIndex(selectedSummonerIndex - 1);
      } else if (key === 'ArrowDown') {
        event.preventDefault();
        if (selectedSummonerIndex === summoners.length - 1) return;
        setSelectedSummonerIndex(selectedSummonerIndex + 1);
      } else if (key === 'Enter') {
        event.preventDefault();
        if (!summoners[selectedSummonerIndex]) return;
        selectSummoner(summoners[selectedSummonerIndex])();
      }
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          setInputFocused(false);
        }
      };

      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }, []);

    useEffect(() => {
      const summonerName = watch('summonerName')?.trim();

      if (summonerName === '' || !inputFocused) {
        return;
      }

      const timer = setTimeout(async () => {
        const { name, gameName, tagLine } = splitSummonerName(summonerName);
        await fetchData(gameName ?? name, tagLine);
      }, 300);

      return () => {
        clearTimeout(timer);
      };
    }, [watch('summonerName')]);

    useEffect(() => {
      setSelectedSummonerIndex(0);
    }, [summoners]);

    useEffect(() => {
      if (listRef.current && searchedVisible) {
        const selectedElement = listRef.current.children[
          selectedSummonerIndex
        ] as HTMLLIElement;
        listRef.current.scrollTop =
          selectedElement.offsetTop - listRef.current.offsetTop;
      }
    }, [selectedSummonerIndex, searchedVisible]);

    return (
      <div ref={containerRef} className={classNames('relative', className)}>
        <input
          type="text"
          disabled={disabled}
          autoComplete="off"
          className={classNames(
            'block w-full p-3 border rounded border-solid border-gray-600 text-sm focus:outline-none bg-gray-850',
            {
              'text-gray-100': !disabled,
              'text-gray-600': disabled,
            }
          )}
          placeholder={
            placeholder ??
            intl.formatMessage(
              {
                id: 'Game Name + {tagLine}',
              },
              {
                tagLine: LOL_REGION_TAG_NAME[getRegion()],
              }
            )
          }
          {...register('summonerName', { required: true })}
          {...rest}
          onFocus={() => setInputFocused(true)}
          onBlur={async (event) => {
            await register('summonerName').onBlur(event);
            addTagLineIfNotExists();
          }}
          onKeyDown={handleInputKeyDown}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
        <ul
          ref={listRef}
          className={classNames(
            'absolute left-0 right-0 top-full z-50 max-h-[180px] overflow-y-scroll text-sm border-gray-600 border-x-[1px] border-b-[1px] box-border',
            {
              'hidden ':
                !searchedVisible || watch('summonerName').trim() === '',
            }
          )}
        >
          {summoners.map((summoner, index) => (
            <li
              key={summoner.id}
              className={classNames(
                'p-3 box-border bg-gray-850 flex cursor-pointer hover:bg-gray-900',
                {
                  'bg-gray-900': selectedSummonerIndex === index,
                }
              )}
              onClick={selectSummoner(summoner)}
            >
              <img
                className="w-6 h-6 rounded-full box-border"
                src={summoner.profile_image_url}
              />
              <div className="ml-2">
                <div>{summoner.game_name}</div>
                <div className="text-gray-300 text-xs">#{summoner.tagline}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

SummonerNameInput.displayName = 'SummonerNameInput';

export default SummonerNameInput;
