import React, { FC } from 'react';
import { ISummonerName } from '../../types/lol/summoner.type';
import { SummonerOptionProps, SummonerProfile } from './index';
import { resolveOpTagUrl, resolveOpUrl } from '../../utils/misc';
import { getRegion } from '../../utils/application';
import { getSummonerName } from '../../utils/tag';
import NameCopyButton from '../NameCopyButton';
import classNames from 'classnames';
import { SteamPartner } from '../../types/steam/partner.type';
import { FormattedMessage } from 'react-intl';

interface Props {
  summoner: ISummonerName & {
    profileImageUrl: string;
  };
  className?: string;
  tft?: boolean;
  options?: SummonerOptionProps;
  copy?: boolean;
}

const FullSummonerProfile: FC<Props> = ({
  summoner,
  options,
  className,
  tft = false,
  copy = true,
}) => {
  return (
    <div className={classNames('flex items-center', className)}>
      <SummonerProfile.Avatar
        src={
          !tft
            ? summoner?.profileImageUrl
            : `https://opgg-static.akamaized.net/meta/images/profile_icons/profileIcon${summoner.profileImageUrl}.jpg?image=q_auto,f_webp,w_64`
        }
      />
      <div>
        <div className="flex items-center">
          <SummonerProfile.Name
            name={summoner?.gameName ?? summoner.name}
            href={
              summoner?.gameName && summoner?.tagLine
                ? resolveOpTagUrl(
                    summoner.gameName,
                    summoner.tagLine,
                    getRegion()
                  )
                : resolveOpUrl(getSummonerName(summoner), getRegion())
            }
          />
          <SummonerProfile.Options {...options} />
          {copy && <NameCopyButton name={getSummonerName(summoner)} />}
        </div>
        <SummonerProfile.Description
          tagLine={summoner?.tagLine}
          certified={options?.certified}
        />
      </div>
    </div>
  );
};

interface SteamProps {
  partner: SteamPartner;
  className?: string;
  copy?: boolean;
}

export const FullSteamProfile: FC<SteamProps> = ({
  partner,
  className,
  copy = true,
}) => {
  return (
    <div className={classNames('flex items-center', className)}>
      <SummonerProfile.Avatar src={partner.steamUser.profileImage} />
      <div>
        <div className="flex items-center">
          <SummonerProfile.Name
            name={partner.steamUser.personaName}
            href={partner.steamUser.profileUrl}
            tooltip={'View on Steam'}
          />
          <SummonerProfile.Options
            steamCertified={partner.isAuth}
            mic={partner.mic}
          />
          {copy && (
            <NameCopyButton
              alert={'Player name copied to clipboard.'}
              name={partner.steamUser.personaName}
            />
          )}
        </div>
        <div className="text-3xs text-gray-500 text-left">
          {partner.isAuth && <FormattedMessage id="Certified" />}
        </div>
      </div>
    </div>
  );
};

export default FullSummonerProfile;
