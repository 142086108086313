import React from 'react';
import { IPartner, PartnerOption } from '../types/PartnerTypes';

export function usePartnerOptions(partner?: IPartner): {
  toggleOption: (option: PartnerOption) => void;
  hasOption: (option: PartnerOption) => boolean;
  rawValue: number;
} {
  const [rawValue, setRawValue] = React.useState(partner?.options ?? 0);

  React.useEffect(() => {
    if (!partner) return;
    setRawValue(partner.options);
  }, [partner?.options]);

  return {
    toggleOption,
    hasOption,
    rawValue,
  };

  function toggleOption(option: PartnerOption) {
    setRawValue(
      (prevValue) => prevValue + (hasOption(option) ? option * -1 : option)
    );
  }

  function hasOption(option: PartnerOption): boolean {
    return (rawValue & option) === option;
  }
}
