import classNames from 'classnames';
import React from 'react';

export function useThemeColors() {
  const getWinRateClassName = React.useCallback(
    (winrate: number, greenOnly = false) => {
      if (winrate === 0) {
        return 'text-gray-500';
      }

      if (winrate === 50) {
        return greenOnly ? 'text-white' : 'text-gray-300';
      }

      return classNames({
        'font-bold': winrate > 50 || !greenOnly,
        'text-green-500': winrate > 50,
        'text-red-500': winrate <= 50 && !greenOnly,
      });
    },
    []
  );

  return {
    getWinRateClassName,
  };
}
