import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import Image from 'next/image';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMemberRedirect } from '../hooks/utils';
import { useCertifiedSummoners } from '../queries/summoners';
import Spinner from './Spinner';
import Tooltip from './Tooltip';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
import { getSummonerName } from '../utils/tag';

type Props = {
  onSelect: (summonerName: string) => void;
  hideNotSelected?: boolean;
};

const SummonerSelect: React.FC<Props> = ({ onSelect, hideNotSelected }) => {
  const intl = useIntl();
  const { summoners, loading } = useCertifiedSummoners();
  const redirectToMemberPage = useMemberRedirect({ destination: 'rso' });

  const [selected, setSelected] = React.useState('');
  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  const handleChange = React.useCallback(
    (e: SelectChangeEvent) => {
      setSelected(e.target.value);
      onSelect(e.target.value);
    },
    [onSelect, setSelected]
  );

  const onVerifyClick = () => {
    dataLayer(
      {
        op_event: GTMEventInfoType.CLICK_SUMMONER_CERTIFICATION,
      },
      GTMEventType.CLICK
    );
    redirectToMemberPage();
  };

  React.useEffect(() => {
    if (!summoners || summoners.length === 0) return;

    const name = getSummonerName(summoners[0]);

    setSelected(name);
    onSelect(name);
  }, [summoners]);

  React.useEffect(() => {
    const timer = setTimeout(() => setTooltipVisible(true), 500);
    return () => clearTimeout(timer);
  }, []);

  if (!summoners || summoners?.length === 0 || loading) {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        flex={1}
        border={1}
        borderColor="text.disabled"
        className="rounded"
        minHeight={(theme) => theme.spacing(5.5)}
      >
        {loading ? (
          <Spinner />
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            mx={2}
          >
            <Typography variant="body2" color="text.secondary">
              <FormattedMessage id="No certified summoners" />
            </Typography>
            <Tooltip
              title={intl.formatMessage({
                id: 'link-riot-account.tooltip',
              })}
              open={tooltipVisible}
              placement="top"
            >
              <Button onClick={onVerifyClick}>
                <Typography>
                  <FormattedMessage id="Verify Summoner" />
                </Typography>
              </Button>
            </Tooltip>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <FormControl
      fullWidth
      size="small"
      sx={(theme) => ({
        flex: 1,
        minHeight: theme.spacing(5.5),
      })}
    >
      <Select
        value={selected}
        onChange={handleChange}
        displayEmpty
        sx={{
          flex: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'text.disabled',
            borderWidth: '1px !important',
          },
          '& .MuiOutlinedInput-notchedOutline:hover': {
            borderColor: 'text.secondary',
          },
        }}
        MenuProps={{
          style: {
            zIndex: 100001,
          },
        }}
      >
        {/*!hideNotSelected && (
          <MenuItem value="">
            <Typography color="text.secondary">
              <FormattedMessage id="Not selected" />
            </Typography>
          </MenuItem>
        )*/}
        {(summoners ?? []).map((summoner) => {
          const { profileImageUrl } = summoner;
          const name = getSummonerName(summoner);
          return (
            <MenuItem key={`cert-summoner-${name}`} value={name}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Image
                  width={24}
                  height={24}
                  src={profileImageUrl}
                  alt="Profile image"
                  className="rounded-full"
                />
                <Typography className="lg:max-w-[180px] truncate">
                  {name}
                </Typography>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SummonerSelect;
