import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getRegion } from '../utils/application';
import SummonerNameInput from './SummonerNameInput';
import SummonerSelect from './SummonerSelect';
import { Box, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useCertifiedSummoners } from '../queries/summoners';

interface Props {
  inputLabel?: string;
  certified?: boolean;
  inputProps: any;
  onSummonerSelect: (summonerName: string) => void;
}

const SummonerName = ({
  certified,
  inputProps,
  onSummonerSelect,
  inputLabel,
}: Props) => {
  const { register, watch, setValue } = useFormContext();
  const { summoners } = useCertifiedSummoners();
  const _certified = certified ?? summoners?.length !== 0;
  return (
    <Stack
      alignItems={'flex-end'}
      direction={{ xs: 'column', lg: 'row' }}
      spacing={1.5}
    >
      {!_certified && (
        <Box
          width={{
            xs: '100%',
            lg: 'auto',
          }}
        >
          <label
            className="block text-2xs text-gray-400 mb-2"
            htmlFor="summonerName"
          >
            <FormattedMessage id={inputLabel ?? 'Summoner Name'} /> (
            {getRegion().toUpperCase()})
          </label>
          <SummonerNameInput
            {...inputProps}
            register={register}
            watch={watch}
            setValue={setValue}
          />
        </Box>
      )}
      <Stack
        width={{
          xs: '100%',
          lg: 'auto',
        }}
        flex={1}
      >
        <label
          className="block text-2xs text-gray-400 mb-2"
          htmlFor="certifiedSummoner"
        >
          <FormattedMessage id="Certified Summoner" /> (
          <FormattedMessage id="Certified summoners may take time to be added." />
          )
        </label>
        <SummonerSelect onSelect={onSummonerSelect} />
      </Stack>
    </Stack>
  );
};

export default SummonerName;
