import { FormattedMessage } from 'react-intl';
import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const RegisterPolicyText = ({ className }: Props) => (
  <div className={classNames('flex flex-row', className)}>
    <img className={'w-5 h-5'} src="/images/icons/info.svg" />
    <p className="text-2xs text-red-500 ml-1 break-words">
      <FormattedMessage
        id="Acts such as insults, defamation, and sexual harassment against others may be subject to legal punishment when reported by the other party."
        values={{
          b: (chunks) => <div className="text-gray-400">{chunks}</div>,
          br: <br />,
        }}
      />
    </p>
  </div>
);

export default RegisterPolicyText;
